
$primary: rgb(122,33,130) !default;
$danger: $primary !default;
$dark: #333434 !default;
$light: rgb(242,242,240) !default;
$secondary: $dark !default;
$body-color: $dark !default;
$body-bg: $light !default;
$head-bg: rgb(217,217,210) !default;
$nav-bg: rgb(196,195,190) !default;
$enable-rounded: true !default;
//$carousel-control-width: 5%;
//$modal-content-bg: $dark !default;
//$modal-content-border-color: rgba($gold, .2) !default;
$headings-font-weight: 700 !default;
//$headings-margin-bottom: $spacer !default;
$hr-color: $dark !default;
//$link-color: $gold !default;
//$navbar-light-color:                $dark !default;
//$navbar-light-hover-color:          $dark !default;
//$navbar-light-active-color:         $dark !default;
//$navbar-light-disabled-color:       $dark !default;
//$navbar-light-toggler-border-color: $dark !default;
//$font-size-base:              1rem !default;
//$h1-font-size:                $font-size-base * 3 !default;
//$h2-font-size:                $font-size-base * 2 !default;
//$h3-font-size:                $font-size-base * 2 !default;
//$h4-font-size:                $font-size-base * 1.5 !default;
//$h5-font-size:                $font-size-base * 1.5 !default;
//$h6-font-size:                $font-size-base !default;
$offcanvas-bg-color:                $body-bg !default;
$offcanvas-color:                   $dark !default;
$navbar-nav-link-padding-x:         1rem !default;
$navbar-light-color:                $dark !default;
$navbar-light-hover-color:          $primary !default;
$navbar-light-active-color:         $primary !default;
$navbar-light-disabled-color:       rgba($dark, .5) !default;
$navbar-light-toggler-border-color: rgba($dark, .1) !default;
$box-shadow-lg:               0 1rem 3rem rgba($dark, .350) !default;

html {
  scroll-behavior: smooth;
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
$utilities-fill-colors: map-loop($utilities-colors, rgba-css-var, "$key", "fill") !default;
$utilities: (
  "fill": (
    property: fill,
    class: fill,
    local-vars: (
      "fill-opacity": 1
    ),
    values: $utilities-fill-colors
  )
) !default;
@import '~bootstrap/scss/bootstrap';
@import 'flag-icons/sass/flag-icons';

.btn, .form-control {
  border-radius: 50rem;
}

.btn-primary {
  @extend .fw-bold;
}

.fixed-basket-margin {
  margin-bottom: -150px;
}

.navbar-light {
  .navbar-nav {
    .nav-link {
      &:hover,
      &:focus {
        color: $navbar-light-hover-color;
        background-color: $light;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }
  }
}

:root {
  --#{$variable-prefix}head-bg: #{$head-bg};
  --#{$variable-prefix}nav-bg: #{$nav-bg};
}

@media (min-width: 400px) {
  .d-xs-inline {
    display: inline !important;
  }
}

@media (min-width: 400px) {
  .d-xs-none {
    display: none !important;
  }
}

@include media-breakpoint-up(xxl) {
  .navbar-expand-xxl .navbar-collapse.opacity-xxl-0 {
    display: none !important;
  }
  #navbar-mobile {
    display: none !important;
  }
}

@include media-breakpoint-down(xxl) {
  #header {
    max-height: 100vh;
    overflow-y: auto;
    .navbar-brand {
      margin-bottom: -10px;
    }
  }
}

main {
  min-height: 45vh;
}

.navbar-cart-icon {
  @extend .navbar-toggler-icon;
  background-image: url('/public/images/cart.svg');
  background-size: 75%;
}

.navbar-info-icon {
  @extend .navbar-toggler-icon;
  background-image: url('/public/images/info.svg');
  background-size: 75%;
}

.navbar-light {
  .navbar-cart-icon {
    background-image: url('/public/images/cart.svg');
  }
  .navbar-info-icon {
    background-image: url('/public/images/info.svg');
  }
}

.fa-check {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  background-image: url(escape-svg("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path fill='#{$dark}' d='M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z'/></svg>"));
}

.offcanvas {
  a:not(.btn) {
    @extend .text-light;
    @extend .text-decoration-none;
    @extend .fw-bold;
  }
  .btn-dark {
    @extend .btn-light;
  }
}

.primary-button {
  @extend .btn;
  @extend .btn-danger;
  @extend .stretched-link;
  @extend .d-flex;
  @extend .d-sm-inline-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  color: $dark;
  &:hover {
    color: $dark;
  }
  &:before {
    display: inline-block;
    width: $btn-line-height * $btn-font-size * 0.8;
    height: $btn-line-height * $btn-font-size * 0.8;
    margin-right: $btn-padding-x * 0.5;
    background: url('/public/images/arrow-right.svg') no-repeat;
    content: '';
  }
}

.card-row {
  @extend .row;
  &.card-row-col-2 {
    @extend .row-cols-md-2;
    @extend .row-cols-xl-4;
    .card-col {
      .card {
        .card-inner {
          @extend .flex-md-row;
          @extend .flex-xl-column;
          @extend .align-items-md-start;
          @extend .align-items-xl-stretch;
          .card-body-col {
            @extend .align-self-stretch;
          }
          .card-image-col {
            @extend .col-md-4;
            @extend .col-xl-12;
          }
        }
      }
    }
  }
  .card-col {
    @extend .col-md;
    @extend .d-flex;
    @extend .py-3;
    .card {
      h3 {
        @extend .h5;
      }
      table {
        @extend .small;
      }
      .card-inner {
        @extend .d-flex;
        @extend .flex-md-column;
        @extend .flex-grow-1;
        @extend .align-items-start;
        @extend .align-items-md-stretch;
        .card-image-col {
          @extend .col-4;
          @extend .col-md-12;
          .card-img-top {
            @extend .overflow-hidden;
          }
        }
        .card-body-col {
          @extend .d-flex;
          @extend .flex-grow-1;
        }
      }
    }
  }
}

.align-items-center-light {
  @extend .align-items-center;
  @extend .bg-light;
  @extend .text-dark;
  @extend .my-3;
}

.align-items-center-dark {
  @extend .align-items-center;
  @extend .bg-dark;
  @extend .text-light;
  @extend .my-3;
}

.ce-headline-center {
  @extend .text-center;
}

.ce-headline-left {
  @extend .text-start;
}

.ce-headline-right {
  @extend .text-end;
}
